<template>
  <div
    :style="{
      height: '100vh'
    }"
  >
    <slot v-if="headerConfig.show" name="header">
      <van-nav-bar
        class="my-van-nav-bar"
        :fixed="headerConfig.fixed"
        :left-arrow="headerConfig.leftArrow"
        :title="headerConfig.title"
        @click-left="clickLeft"
      />
    </slot>

    <slot name="main">
      <div
        class="overflow-y-auto"
        :style="{
          height: `calc(100% - ${layoutTop})`,
          paddingTop: `${layoutTop}`
        }"
      >
        <slot />
      </div>
    </slot>

    <slot name="footer" />
  </div>
</template>

<script setup lang="ts">
import { reactive, onMounted } from 'vue'
import { getVantNavBarHeight, disableZoom } from '@/utils/index'
import { isInWechat } from '@/utils/device'

const router = useRouter()
const layoutTop = ref(0)
const headerConfig = reactive({
  show: false,
  fixed: true,
  leftArrow: true,
  title: ''
})

onMounted(async () => {
  await nextTick()

  // 更改页面标题
  headerConfig.title = document.title

  // 是否展示头部
  headerConfig.show = !isInWechat() && !isInQQ() && !isInApp

  // 获取菜单高度
  setTimeout(() => {
    layoutTop.value = `${getVantNavBarHeight()}px`
  })

  disableZoom()
})

const clickLeft = () => {
  router.back()
}
</script>

<style lang="scss">
html,
.bg-white {
  background-color: $color-grey-732;
}

@for $i from 0 through 10 {
  .f#{$i*1 + 12} {
    font-size: 1px * $i + 12 !important;
  }

  .mt#{$i*2} {
    margin-top: 2px * $i !important;
  }
  .mr#{$i*2} {
    margin-right: 2px * $i !important;
  }
  .mb#{$i*2} {
    margin-bottom: 2px * $i !important;
  }
  .ml#{$i*2} {
    margin-left: 2px * $i !important;
  }

  .mt#{$i*5} {
    margin-top: 5px * $i !important;
  }
  .mr#{$i*5} {
    margin-right: 5px * $i !important;
  }
  .mb#{$i*5} {
    margin-bottom: 5px * $i !important;
  }
  .ml#{$i*5} {
    margin-left: 5px * $i !important;
  }

  .pt#{$i*2} {
    padding-top: 2px * $i !important;
  }
  .pr#{$i*2} {
    padding-right: 2px * $i !important;
  }
  .pb#{$i*2} {
    padding-bottom: 2px * $i !important;
  }
  .pl#{$i*2} {
    padding-left: 2px * $i !important;
  }

  .pt#{$i*5} {
    padding-top: 5px * $i !important;
  }
  .pr#{$i*5} {
    padding-right: 5px * $i !important;
  }
  .pb#{$i*5} {
    padding-bottom: 5px * $i !important;
  }
  .pl#{$i*5} {
    padding-left: 5px * $i !important;
  }
}
</style>

<style lang="scss" scoped>
:deep(.my-van-nav-bar) {
  line-height: 22px;

  .van-nav-bar {
    &__content {
      height: 46px;
    }

    &__left {
      padding: 0 15px;
    }

    &__arrow {
      font-size: 20px;
      color: $color-black-153;

      &::before {
        content: '\e64d';
        font-family: 'icon';
      }
    }

    &__title {
      font-size: 17px;
      color: $color-black-153;
    }
  }
}
</style>
